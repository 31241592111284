import React from "react"
import SubscriptionUpdates from "../../components/activity/subscription-updates"
import SEO from "../../components/common/seo"
import FactorGraphView from '../../components/factor-graph/FactorGraphView'
import UrlSearch from "../../components/url/url-search"

const GraphPage = ({location}:any) => {
  return (
    <>
      <SEO title="NavAbility Factor Graph" />
      <UrlSearch location={location}/>
      {/* <Paywall> */}
        <FactorGraphView />
        <SubscriptionUpdates />
      {/* </Paywall> */}
    </>
  )
}

export default GraphPage;
