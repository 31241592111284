export const GQL_GET_URS = `
query sdk_get_URS($USER_LABEL: EmailAddress!)
{
    users (where: {label: $USER_LABEL}) {
      id
      label
      robots (options: {sort: {label: ASC}}) {
        id
        label
        createdTimestamp
        lastUpdatedTimestamp
        sessions (options: {sort: {label: ASC}}) {
          id
          label
          createdTimestamp
          lastUpdatedTimestamp
        }
      }
    }
  }
`