import { useApolloClient } from '@apollo/client';
import { Add, Remove } from '@mui/icons-material';
import { TreeItem, TreeItemProps, TreeView } from '@mui/lab';
import { Alert, Box, Collapse, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { TransitionProps } from 'react-spring/renderprops';
import { useAppDispatch } from '../../app/hooks';
import { UserState, selectUser } from '../../global/state/user-slice';
import { getURSApi } from '../../services/factorgraph/factorgraph-api';
import { Robot, Session } from '../../types/base';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import { CameraIcon, HexagonalIcon, SolveSwarmIcon, UserIcon } from '../icons/icons';
import { FactorGraphVizState, selectFactorGraphView, setStatusLeftPane, setUser } from './FactorGraphVizSlice';

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<any>;
  labelInfo?: any; //Text or button.
  labelText: string;
};

function StyledTreeItem(props: StyledTreeItemProps) {
  const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, ...other } = props;

  return (
    <TreeItem
      label={
        <Box style={{ display: 'flex', alignItems: 'center', padding: 0.5, paddingRight: 0 }}>
          {props.labelIcon}
          <Typography style={{ fontWeight: 'inherit', flexGrow: 1 }}>{labelText}</Typography>
          <Box style={{ marginLeft: '10px' }}>{labelInfo}</Box>
        </Box>
      }
      {...other}
    ></TreeItem>
  );
}

export default function UserTreeBox(props: {
  onSessionClicked: (context: { userLabel: string; robot: Robot; session: Session }) => void;
}) {
  const navAbilityClient = useApolloClient();
  const dispatch = useAppDispatch();
  const factorGraphVizView: FactorGraphVizState = useSelector(selectFactorGraphView);

  const userState: UserState = useSelector(selectUser);

  // When we get the user account, get all the user information...
  React.useEffect(async () => {
    if (typeof window !== `undefined`) {
      if(userState.user) {
      // navAbilityClient.query({ query: ACCOUNT_QUERY }).then(async (result) => {
      //   setAccount(result.data.account);
      //   dispatch(setStatusLeftPane({ status: 'loading', error: null }));
        try {
          const user = await getURSApi(navAbilityClient, userState.user.label);
          dispatch(setUser(user));
          dispatch(setStatusLeftPane({ status: 'idle', error: null }));
        } catch (error) {
          dispatch(setStatusLeftPane({ status: 'idle', error: error }));
        }
      }
      // });
    }
  }, [userState.user?.label]);

  return (
    <Box>
      {factorGraphVizView.errorLeftPane !== null ? (
        <Alert severity="error">{factorGraphVizView.errorLeftPane}</Alert>
      ) : null}
      {factorGraphVizView.statusLeftPane == 'loading' ? (
        <CircularProgressWithLabel label="Loading..." style={{ width: '96px', height: '96px', margin: '20px' }} />
      ) : (
        <TreeView
          aria-label="customized"
          defaultExpanded={['1']}
          defaultCollapseIcon={<Remove />}
          defaultExpandIcon={<Add />}
        >
          <StyledTreeItem
            key="1"
            nodeId="1"
            labelText={factorGraphVizView.user?.label}
            labelIcon={<UserIcon style={{ marginRight: '5px' }} />}
          >
            {factorGraphVizView.user?.robots?.map((robot, index) => {
              return (
                <StyledTreeItem
                  key={'ROBOT_' + robot.label + index}
                  nodeId={'ROBOT_' + robot.label + index}
                  labelText={robot.label}
                  labelIcon={<SolveSwarmIcon style={{ marginRight: '5px' }} />}
                >
                  {robot.sessions?.map((session, index) => (
                    <Tooltip title="Click to visualize this session">
                      <StyledTreeItem
                        key={'SESSION_' + session.label + index}
                        nodeId={'SESSION_' + session.label + index}
                        labelIcon={<HexagonalIcon style={{ marginRight: '5px' }} />}
                        labelText={session.label}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.onSessionClicked({ userLabel: userState.user.label, robot: robot, session: session });
                        }}
                        labelInfo={<CameraIcon />}
                      />
                    </Tooltip>
                  ))}
                </StyledTreeItem>
              );
            })}
          </StyledTreeItem>
        </TreeView>
      )}
    </Box>
  );
}
