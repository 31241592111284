import { gql } from '@apollo/client';

import { NavAbilityClient } from 'navabilitysdk';
import { User } from '../../types/base';
import { GQL_GET_URS } from './graphql/URS';

/**
 * Get the latest robots and their latest sessions.
 *
 * @export
 * @param {NavAbilityClient} navAbilityClient
 * @param {string} userName The name of the user.
 * @return {*}  {Promise<User>}
 */
export async function getURSApi(navAbilityClient: NavAbilityClient, userLabel: string): Promise<User> {
  const response = await navAbilityClient.query({
    query: gql(`${GQL_GET_URS}`),
    fetchPolicy: 'network-only',
    variables: {
      USER_LABEL: userLabel,
    },
  });

  if (response.data.errors) {
    throw Error(`Error: ${response.data.errors[0]}`);
  } else {
    // Get the first user otherwise undefined.
    return response.data.users.length > 0 ? response.data.users[0] : undefined;
  }
}
