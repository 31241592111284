import { Alert, Box, Typography } from '@mui/material';
import ForceGraph, { NodeObject } from 'force-graph';
import { Factor, Variable } from 'navabilitysdk';
import React from 'react';
import { useSelector } from 'react-redux';
import theme from '../../global/layout/theme';
import CircularProgressWithLabelModal from '../common/CircularProgressWithLabelModal';
import { FactorGraphVizState, selectFactorGraphView } from './FactorGraphVizSlice';

// Small enum for type.
export enum NODETYPE {
  VARIABLE,
  FACTOR,
}

/**
 * A force-directed topology graph for showing large factor graphs.
 * For now this can show a single session but we can easily extend it to showing an enterprise graph.
 * Reference: https://github.com/vasturiano/force-graph.
 *
 * Has an optional onClick event for detecting when nodes are clicked.
 *
 * @export
 * @param {({ onNodeClicked: (node: Variable | Factor) => void })} props
 */
export default function FactorGraphVisualizationBox(props: { onNodeClicked?: (node: NodeObject) => void }) {
  const graphContainer = React.useRef(null);
  const factorGraphVizView: FactorGraphVizState = useSelector(selectFactorGraphView);
  var graph = null;

  function nodePaint(node: NodeObject, ctx, globalScale) {
    const foreColor = '#f3fefb';
    const backColor = node.type == NODETYPE.VARIABLE ? theme.palette.secondary.main : theme.palette.primary.main;

    const label = node.id;
    const fontSize = 12 / globalScale;
    const textWidth = ctx.measureText(label).width;
    const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 0.5); // some padding

    // Make the circle/square.
    ctx.fillStyle = backColor;
    if (node.type == NODETYPE.VARIABLE) {
      // Circle
      ctx.beginPath();
      ctx.arc(node.x, node.y, bckgDimensions[0], 0, 2 * Math.PI, false);
      ctx.fill();
    } else {
      // Rectangle
      ctx.fillRect(node.x! - bckgDimensions[0] / 2.0, node.y! - bckgDimensions[1] / 2.0, ...bckgDimensions);
    }

    // Add the label
    ctx.fillStyle = foreColor;
    ctx.font = `${fontSize}px Montserrat`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(label, node.x, node.y);

    node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
  }

  /**
   * Create a graph from the session data in factorGraphVizView.
   *
   * @return {*}
   */
  function sessionToGraph() {
    const sessionData = factorGraphVizView.loadedSession;
    if (sessionData == null) return;

    // Make a copy and make it as small as possible (really affects performance)
    const nodes = [
      ...sessionData.variables.map((v) => {
        return {
          id: v.label,
          name: v.label,
          val: v.label.length,
          type: NODETYPE.VARIABLE,
          description: `${v.label}: ${v.tags.join(', ')}`,
        }; //Add an ID for force-graph
      }),
      ...sessionData.factors.map((f) => {
        return {
          id: f.label,
          name: f.label,
          val: f.label.length,
          type: NODETYPE.FACTOR,
          description: `${f.label}: ${f.tags.join(', ')}`,
        }; //Add an ID for force-graph
      }),
    ];
    var links = [];
    for (const factor of sessionData.factors) {
      for (const t of factor._variableOrderSymbols) {
        links.push({ source: factor.label, target: t });
      }
    }

    //REF: For sizing: https://github.com/vasturiano/force-graph/issues/207
    const container = document.getElementById('graph');
    // TODO: Fix height - Why is height wrong?
    // console.log(container.clientHeight);

    //Skip if Gatsby is building

    graph = ForceGraph()(document.getElementById('graph'))
      .graphData({ nodes, links })
      .nodeLabel('description')
      .width(container!.clientWidth)
      .height(container!.clientHeight)
      .nodeCanvasObject((node, ctx, globalScale) => nodePaint(node, ctx, globalScale))
      .nodePointerAreaPaint((node, color, ctx) => {
        // This apparently makes the collision box.
        ctx.fillStyle = color;
        const bckgDimensions = node.__bckgDimensions;
        bckgDimensions &&
          ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
      })
      // .zoomToFit(500) //Doesn't work for some reason
      .linkWidth(3)
      // .enableNodeDrag(false)
      .onNodeClick((node) => {
        // Send the summary node up
        props.onNodeClicked && props.onNodeClicked(node);
      });
  }

  React.useEffect(() => {
    // Make the graph whenever the loaded session changes.
    sessionToGraph();
  }, [factorGraphVizView.loadedSession, factorGraphVizView.statusViz]);

  return (
    <Box id="parentElement">
      {factorGraphVizView.errorViz !== null ? <Alert severity="error">{factorGraphVizView.errorViz}</Alert> : null}
      {factorGraphVizView.statusViz == 'loading' ? (
        <CircularProgressWithLabelModal />
      ) : factorGraphVizView.loadedSession == null ? (
        <Typography>Select a session in the left pane to view the data.</Typography>
      ) : null}
      <Box id="graph" ref={graphContainer}></Box>
    </Box>
  );
}
