import { Backdrop, Box, CircularProgressProps } from '@mui/material';
import React from 'react';
import theme from '../../global/layout/theme';
import CircularProgressWithLabel from './CircularProgressWithLabel';

export default function CircularProgressWithLabelModal(
  props: CircularProgressProps & { label?: string; onClick?: () => void; buttonLabel?: string },
) {
  return (
    <Box style={{ position: 'relative', minHeight: "120px"}}>
        <Backdrop
          open={true}
          style={{
            // NOTE: None of this is working... will have to come back to it.
            position: 'absolute',
            left: 0,
            backgroundColor: "rgb(0,0,0,0)",
            right: 0,
            bottom: 0,
            minHeight: "120px",
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgressWithLabel {...props} label="Loading..." style={{ width: '96px', height: '96px' }} />
        </Backdrop>
      </Box>
  );
}
