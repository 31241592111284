import { Alert, Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import NodeViewerBox from '../common/data-views/NodeViewerBox';
import { FactorGraphVizState, selectFactorGraphView } from './FactorGraphVizSlice';

/**
 * Show the properties of the node.
 *
 * @export
 * @return {*}
 */
export default function NodeDataBox() {
  const factorGraphVizView: FactorGraphVizState = useSelector(selectFactorGraphView);

  return (
    <Box>
      {factorGraphVizView.errorRightPane !== null ? <Alert severity='error'>{factorGraphVizView.errorRightPane}</Alert> : null}
      {factorGraphVizView.statusRightPane == 'loading' ? (
        <CircularProgressWithLabel label="Loading..." style={{ width: '96px', height: '96px', margin: '20px' }} />
      ) : factorGraphVizView.selectedNode == null ? (
        <Typography>Select a node to display its properties.</Typography>
      ) : (
        <NodeViewerBox variableOrFactor={factorGraphVizView.selectedNode} />
      )}
    </Box>
  );
}
