import { useApolloClient } from '@apollo/client';
import { Box, Grid, Paper } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { NodeObject } from 'force-graph';
import { QueryDetail, getFactor, getFactors, getVariable, getVariables } from 'navabilitysdk';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { Robot, Session } from '../../types/base';
import FactorGraphVisualizationBox, { NODETYPE } from './FactorGraphVisualizationBox';
import {
  selectFactorGraphView,
  setLoadedSession,
  setSelectedNode,
  setStatusRightPane,
  setStatusViz,
} from './FactorGraphVizSlice';
import NodeDataBox from './NodeDataBox';
import UserTreeBox from './UserTreeBox';

export default function FactorGraphView() {
  const navAbilityClient = useApolloClient();
  const dispatch = useAppDispatch();
  const factorGraphVizView = useSelector(selectFactorGraphView);

  // When the user clicks a session, load it here.
  async function visualizeContext(context: { userLabel: string; robot: Robot; session: Session }) {
    dispatch(setLoadedSession(null));
    dispatch(setStatusViz({ status: 'loading', error: null }));
    try {
      // Load the whole session.
      const client = {
        userLabel: context.userLabel,
        robotLabel: context.robot?.label,
        sessionLabel: context.session?.label,
      };
      const variables = await getVariables(navAbilityClient, client, QueryDetail.SKELETON);
      const factors = await getFactors(navAbilityClient, client);
      dispatch(
        setLoadedSession({ robot: context.robot, session: context.session, variables: variables, factors: factors }),
      );
      dispatch(setStatusViz({ status: 'idle', error: null }));
    } catch (error) {
      dispatch(setStatusViz({ status: 'loading', error: error }));
    }
  }

  async function selectVariableOrFactor(nodeLabel: string, type: NODETYPE) {
    dispatch(setStatusRightPane({ status: 'loading', error: null }));
    try {
      if (factorGraphVizView.user && factorGraphVizView.loadedSession) {
        // Load the whole session.
        const client = {
          userLabel: factorGraphVizView.user!.label,
          robotLabel: factorGraphVizView.loadedSession!.robot.label,
          sessionLabel: factorGraphVizView.loadedSession!.session.label,
        };

        const node =
          type == NODETYPE.VARIABLE
            ? await getVariable(navAbilityClient, client, nodeLabel)
            : await getFactor(navAbilityClient, client, nodeLabel);

        dispatch(setSelectedNode(node));
        dispatch(setStatusRightPane({ status: 'idle', error: null }));
      }
    } catch (error) {
      dispatch(setStatusRightPane({ status: 'loading', error: error }));
    }
  }

  return (
    <Box id="drawer-container" component="div" style={{ position: 'relative', height: '92vh' }}>
      <Grid container alignItems="stretch" spacing={1}>
        <Grid item xs={2}>
          <Card>
            <CardHeader title={'Your Data'} />
            <CardContent style={{ height: '85vh', overflowY: 'auto', marginRight: '10px' /*For the scrollbar*/ }}>
              <UserTreeBox onSessionClicked={visualizeContext} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={7}>
          <Card>
            <CardHeader
              title="Factor Graph Topology"
              subheader="This view is a mathematical representation of the relationships between variables and factors in the factor graph. Select a session by clicking the camera icon in the left pane to see its data."
            />
            <CardContent style={{ height: '80vh' }}>
              <Paper style={{ height: '76vh', overflowY: 'hidden' }}>
                <FactorGraphVisualizationBox
                  onNodeClicked={(node: NodeObject) => selectVariableOrFactor(node.id, node.type)}
                />
              </Paper>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <CardHeader
              title={'Node Details'}
              subheader={
                factorGraphVizView.selectedNode !== null
                  ? `${factorGraphVizView.selectedNode.label}` //${factorGraphVizView.selectedNode.__typename}
                  : ''
              }
            />
            <CardContent style={{ height: '83vh', overflow: 'auto' }}>
              <NodeDataBox />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
